<template>
  <div class="userAuthorizationDetail content">
    <div class="contentbox">
      <p class="userName">授权用户：{{ userName }}</p>
      <vue-scroll :ops="ops" :style="{ width: '100%' }">
        <TableQuery
          :formList="formList"
          :formInline="formInline"
          :customArrKey="customArrKey"
          :btnList="btnList"
          :property="queryProperty"
          @btnClickFun="btnClickFun"
          @moveQueryFun="queryBackFun"
          @formDataFun="formDataFun"
        >
        </TableQuery>
        <div class="p-t-10"></div>
        <p class="tips">
          说明：角色授权的视频，只能通过角色的配置调整视频权限，不可直接移除该视频权限！
        </p>
        <div class="p-t-10"></div>
        <div ref="tableBox">
          <SuperTable
            ref="mytable"
            :tableData="tableData"
            :loading="tableLoading"
            :property="tableProperty"
            :pageData="pageData"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"
            @operationClick="operationClick"
          >
          </SuperTable>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import TableQuery from "@/common/module/TableQuery";
import SuperTable from "@/common/module/SuperTable";
export default {
  name: "userAuthorizationDetail",
  components: {
    TableQuery,
    SuperTable
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      formList: [
        {
          clearable: true,
          name: "typecode",
          showhide: true,
          placeholder: "请选择视频类型",
          label: "视频类型",
          type: "treeselect",
          treedata: []
        },
        {
          name: "videoname",
          label: "视频名称",
          showhide: true,
          type: "text",
          clearable: true,
          placeholder: "请输入视频名称"
        }
      ],
      formInline: {
        typecode: "",
        videoname: ""
      },
      customArrKey: [],
      btnList: [
        {
          name: "searchButton",
          icon: "icon-tianjia",
          label: "查询",
          type: "searchButton"
        }
      ],
      queryProperty: {
        labelWidth: "120px",
        typeName: "cedian",
        moveLabelWidth: "120px"
      },
      /* 表格数据 */
      tableData: {
        listData: [],
        titleData: [],
        btnData: []
      },
      /* 表格加载 */
      tableLoading: false,
      /* 表格属性 */
      tableProperty: {
        typeName: "",
        emptyText: "",
        fixheight: false,
        isOrder: true, // 设置表格有序号
        // border: true, // 设置表格有边框
        operationWidth: "140px",
        hideOperation: true
      },
      /* 分页 */
      pageData: {
        currentPage: 1,
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0
      },
      loading: false,
      btnAuthority: false,
      userName: ""
    };
  },
  created() {
    this.findByDomainid();
    this.resetArr();
    this.getTableList(); // 表格列表数据
  },
  mounted() {
    this.userName = this.$route.query.userName;
    this.cssFun();
    let that = this;
    // eslint-disable-next-line prettier/prettier
    window.onresize = function () {
      that.cssFun();
    };
  },
  methods: {
    changeTreeNew(val) {
      let arr = [];
      if (val.length !== 0) {
        val.map(item => {
          let obj = {};
          obj.id = item.typeCode;
          obj.label = item.text;
          if (item.nodes && item.nodes.length >= 1) {
            obj.children = this.changeTreeNew(item.nodes);
          }
          arr.push(obj);
        });
      }
      return arr;
    },
    findByDomainid() {
      this.$api("videoManage.getVideoTypeTree")
        .invoke()
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            let dataList = data.data;
            if (dataList) {
              let treedata = [];
              if (dataList.length) {
                dataList.map((e, i) => {
                  treedata.push({
                    id: this.changeTreeNew(dataList)[i].id,
                    label: this.changeTreeNew(dataList)[i].label,
                    children: this.changeTreeNew(dataList)[i].children
                  });
                });
              } else {
                treedata = [];
              }
              treedata.unshift({
                id: "",
                label: "全部"
              });
              this.formInline.typecode = treedata[0].id;
              const len = this.formList.length;
              for (let i = 0; i < len; i++) {
                if (this.formList[i].name === "typecode") {
                  this.formList[i].treedata = treedata;
                }
              }
            }
          } else {
          }
        })
        .catch(() => {});
    },
    /*样式*/
    cssFun() {
      let that = this;
      // eslint-disable-next-line prettier/prettier
      that.$nextTick(function () {
        // eslint-disable-next-line prettier/prettier
        setTimeout(function () {
          that.$refs.tableBox.style.height =
            window.innerHeight -
            that.$refs.tableBox.offsetTop -
            120 -
            48 +
            "px";
          if (that.$refs.mytable) {
            that.$refs.mytable.tableCssFun();
            that.$refs.mytable.scrollTableCssFun();
          }
        }, 200);
      });
    },
    resetArr() {
      this.tableData.titleData = [
        {
          headeralign: "center",
          prop: "authtypeName",
          showoverflowtooltip: true,
          showhide: true,
          label: "权限来源",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "typename",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频类型",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "videoname",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频名称",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "time",
          showoverflowtooltip: true,
          showhide: true,
          label: "有效期",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "a",
          showoverflowtooltip: true,
          showhide: true,
          label: "权限",
          type: "icon_click",
          align: "center",
          width: 150
        }
      ];
      this.btnAuthority =
        this.$store.state.operationPower.edit.SHJ_ControllerUnit;
      if (this.btnAuthority) {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          },
          {
            name: "backButton",
            icon: "icon-tianjia",
            label: "返回",
            type: "backButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "detailsButton",
            icon: "iconxiangqing1",
            label: "详情",
            type: "detailsButton"
          }
        ];
      } else {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          },
          {
            name: "backButton",
            icon: "icon-tianjia",
            label: "返回",
            type: "backButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "deleteButton",
            icon: "iconshanchu",
            label: "删除",
            type: "deleteButton"
          }
        ];
      }
    },
    getTableList() {
      let obj = {
        videoname: this.formInline.videoname,
        typecode: this.formInline.typecode,
        userId: this.$route.query.userId,
        page: this.pageData.currentPage,
        rows: this.pageData.pageSize
      };
      this.$api("videoManage.getVideoWithAuthList")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            if (data) {
              data.data.map(item => {
                if (item.authtype == "2") {
                  item.a = ["deleteButton"];
                  item.authtypeName = "直接授权";
                  if (item.begintime && item.endtime) {
                    item.time = item.begintime + "至" + item.endtime;
                  }
                } else if (item.authtype == "1") {
                  item.a = [];
                  item.authtypeName = "角色授权";
                  item.time = "长期有效";
                } else {
                  item.a = [];
                  item.authtypeName = "未授权";
                }
              });
              this.tableData.listData = data.data;
              this.pageData.total = data.total;
            }
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        })
        .catch(() => {});
    },
    /* 当前页-改变事件 */
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.getTableList();
    },
    /* 每页多少条-改变事件 */
    handleSizeChange(val) {
      this.pageData.currentPage = 1;
      this.pageData.pageSize = val;
      this.getTableList();
    },
    // 表格列表上的点击事件
    operationClick(item, scope) {
      let that = this;
      if (item.name === "deleteButton") {
        this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            that.deleteFun(scope.row);
          })
          .catch(() => {
            that.$message({
              type: "info",
              message: "已取消删除",
              duration: 1000
            });
          });
      }
    },
    // 删除
    deleteFun(val) {
      let obj = {
        pkId: val.pkId
      };
      this.$api("videoManage.deletevideoUserLimits")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            this.$message({
              showClose: true,
              message: "删除成功！",
              type: "success",
              duration: 1000
            });
            this.getTableList();
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        });
    },
    // 按钮点击事件
    btnClickFun(item) {
      if (item.name === "searchButton") {
        // 查询
        this.pageData.currentPage = 1;
        this.getTableList();
      } else if (item.name === "backButton") {
        this.$router.push({
          path: "/index/leftMenuIndex/userAuthorization",
          query: {
            menuid: this.$route.query.menuid,
            sysmodel: this.$route.query.sysmodel,
            moduleName: this.$route.query.moduleName
          }
        });
      }
    },
    // 回车查询
    queryBackFun(formInline) {
      this.formInline = formInline;
      // 查询
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    // 查询标签点击事件
    formDataFun(formInline) {
      this.formInline = formInline;
    }
  }
};
</script>
<style scoped lang="less">
.icon-video-right-1 {
  font-size: 20px;
  color: #41bf3a;
}
.userName {
  padding-bottom: 10px;
  font-size: 16px;
  color: #909399;
}
.tips {
  color: #ff0000;
  line-height: 24px;
}
</style>
